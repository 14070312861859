import React, { useState } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
// import { connect } from "react-redux"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// users
import user1 from "../../../assets/images/users/user-3.jpg"

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
          />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu">
          {/* <DropdownItem
            className="d-flex justify-content-start align-items-center"
            tag="a"
            href="/profile"
          >
            <i className="mdi mdi-account-circle font-size-17 text-muted align-middle me-1" />
            {/* {props.t("Profile")}{" "} 
            <span>Profile</span>
            <span className="badge bg-success ms-auto"></span>
          </DropdownItem> */}
          <DropdownItem className="d-flex justify-content-start align-items-center">
            <Link className="w-100" to="/profile">
              <i className="mdi mdi-account-circle font-size-17 text-muted align-middle me-1" />
              {/* {props.t("Profile")}{" "} */}
              <span>Profile</span>
              <span className="badge bg-success ms-auto"></span>
            </Link>
          </DropdownItem>
          <DropdownItem
            className="d-flex justify-content-start align-items-center"
            tag="a"
            href="#"
          >
            <i className="mdi mdi-wallet font-size-17 text-muted align-middle me-1" />
            <span>Wallet</span>
            <span className="badge bg-success ms-auto">2</span>
            {/* {props.t("My Wallet")} */}
          </DropdownItem>
          <DropdownItem
            className="d-flex justify-content-start align-items-center"
            href="#"
          >
            <i className="mdi mdi-cog font-size-17 text-muted align-middle me-1"></i>
            <span>Settings</span>
            {/* {props.t("Settings")} */}
            <span className="badge bg-success ms-auto">11</span>
          </DropdownItem>
          <DropdownItem
            className="d-flex justify-content-start align-items-center"
            tag="a"
            href="auth-lock-screen"
          >
            <i className="mdi mdi-lock-open-outline font-size-17 text-muted align-middle me-1" />
            <span>Lock Screen</span>
            <span className="badge bg-success ms-auto"></span>
            {/* {props.t("Lock screen")} */}
          </DropdownItem>

          <div className="dropdown-divider" />
          <Link
            to="/logout"
            className="d-flex justify-content-start align-items-center dropdown-item text-danger"
          >
            <i className="mdi mdi-power font-size-17 text-muted align-middle me-1 text-danger" />
            <span>Logout</span>
            <span className="badge bg-success ms-auto"></span>
            {/* <span>{props.t("Logout")}</span> */}
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

// const mapStatetoProps = state => {
//   const { error, success } = state.Profile
//   return { error, success }
// }

// export default withRouter(
//   connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
// )

export default ProfileMenu
