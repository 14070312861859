import { axiosInstance, instanceAuth } from "api/apiConfig"

export const allApplicationsFetchAPI = async data => {
  try {
    console.log("Fetch all applications params in api", data)
    const response = await instanceAuth.get("StudentApplication/ApplicationData", {
      params: {
        ...data,
      },
    })
    console.log("response", response)
    return response
  } catch (error) {
    console.log("error fetching applications data...", error)
    throw error
  }
}

export const applicationFetchAPI = async id => {
  try {
    console.log("Fetch applicatio detail params in api", id)
    const response = await instanceAuth.get("StudentApplication/StudentApplicationData", {
      params: {
        stdId: id,
      },
    })
    return response
  } catch (error) {
    console.log("error fetching applications data...", error)
    throw error
  }
}

export const applicationsCreateAPI = async data => {
  console.log("Create applications in helper", data)
  try {
    const response = await instanceAuth.post("StudentApplication/StudentApplicationData", data)
    return response
  } catch (error) {
    console.log("error creating application...", error)
    throw error
  }
}

export const applicationsUpdateAPI = async ({ id: id, data: data }) => {
  try {
    console.log(`Update applications in helper with id: ${id}`, data)
    const response = await instanceAuth.put(`StudentApplication/StudentApplicationData/${id}`, data);
    return response
  } catch (error) {
    console.log("error updating applications...", error)
    throw error
  }
}

export const applicationsDeleteAPI = async id => {
  try {
    console.log(`Delete applications in helper with id: ${id}`)
    const response = await instanceAuth.delete(`StudentApplication/StudentApplicationData/${id}`)
    return response
  } catch (error) {
    console.log("error deleting applications...", error)
    throw error
  }
}

export const applicationsQuestions = async () => {
  try {
    console.log(`Get applications questions:`)
    const response = await instanceAuth.get(`QuestionAnswer/GetQuestions`)
    return response
  } catch (error) {
    console.log("error getting applications questions", error)
    throw error
  }
}

export const applicationAssignStaffs = async (data) => {
  try {
    const response = await instanceAuth.post(`StudentStaff/AssignStudentStaff`, data)
    return response
  } catch (error) {
    console.log("error assigning staff", error)
    throw error
  }
}

export const applicationAssignStaffsUpdate = async (data) => {
  try {
    console.log('update staff')
    const response = await instanceAuth.put(`StudentStaff/UpdatedAssignedStudentStaff`, data)
    return response
  } catch (error) {
    console.log("error assigning staff", error)
    throw error
  }
}
