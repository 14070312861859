import React, { useEffect, useState } from "react"
import { Col, Row } from "reactstrap"
import { DateFormater } from "utilities/dateformater"
import DonarMasterForm from "./donar-master-form"
import DonarMasterTable from "./donar-master-table"

export default function DonarMaster() {
  const [create, setCreate] = useState(false)
  const [donarMasterData, setDonarMasterData] = useState({})
  const [updateId, setUpdateId] = useState()
  const [viewState, setViewState] = useState(false)

  const forView = d_m_data => {
    console.log("for view donor master", d_m_data)
    setUpdateId(d_m_data.id)
    setDonarMasterData(d_m_data)
    setCreate(true)
    setViewState(true)
  }

  const forUpdate = d_m_data => {
    console.log("for update donor master", d_m_data)
    setUpdateId(d_m_data.id)
    setDonarMasterData(d_m_data)
    setCreate(true)
    setViewState(false)
  }

  const toggleTab = () => {
    setUpdateId()
    setDonarMasterData({})
    setCreate(!create)
  }

  return (
    <div className="">
      <Row className="mb-4">
        <Col className="justify-content-end d-flex">
          <button className="switch-btn" onClick={toggleTab}>
            {create ? "View" : "Create"}
          </button>
        </Col>
      </Row>
      {create ? (
        <DonarMasterForm
          donarMasterData={donarMasterData}
          updateId={updateId}
          toggleTab={toggleTab}
          viewState={viewState}
        />
      ) : (
        <DonarMasterTable forUpdate={forUpdate} forView={forView} />
      )}
    </div>
  )
}
