import {
  InputField,
  SelectField,
  TextareaField,
} from "components/Common/input-fields"
import React from "react"
import { Table } from "reactstrap"

export default function EducationExpensesInfoForm({
  tabs,
  updateTabComplete,
  handleChangeInfo,
  handleFileChange,
  handleFileRemove,
  educationExpenseInfo,
  academicsInfo,
  forView,
}) {
  let infoObject = "educationExpenses"

  // Dynamically create columns based on the durationOfCourse
  const columns = ["Expenses", "Total"]
  for (let i = 1; i <= academicsInfo?.programDuration; i++) {
    columns.push(`Year ${i}`)
  }

  // Handle table cell changes
  const handleCellChange = (rowIndex, key, value) => {
    const updatedExpenses = [...educationExpenseInfo.expenseList]
    updatedExpenses[rowIndex][key] = value

    // Update the state with the new expenseList
    handleChangeInfo(infoObject, "expenseList", updatedExpenses)
  }

  return (
    <React.Fragment>
      <h5 className="mt-4 text-secondary-si section-heading page-break">
        Education Expenses Details
      </h5>

      <SelectField
        label="Currency"
        compulsory={true}
        disabled={forView}
        type="text"
        selectOptions={["PKR", "INR", "CAD"]}
        value={educationExpenseInfo.currency}
        placeholder="Type Currency"
        onChange={e => handleChangeInfo(infoObject, "currency", e.target.value)}
      />

      <div className="table-responsive rounded-3">
        <Table>
          <thead>
            <tr>
              <th className="text-secondary-si">Expense</th>
              {Array.from({ length: academicsInfo.programDuration }).map(
                (_, i) => (
                  <th key={i} className="text-secondary-si text-nowrap">
                    Year {i + 1}
                  </th>
                ),
              )}
              <th className="text-secondary-si">Total</th>
            </tr>
          </thead>
          <tbody>
            {educationExpenseInfo?.expenseList ? educationExpenseInfo?.expenseList.map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td>
                  {forView ? (
                    row?.expense
                  ) : (
                    <InputField
                      type="text"
                      className="col-sm-12 col-md-12 col-lg-12 my-2"
                      value={row?.expense}
                      placeholder="Enter Expense Name"
                      onChange={e =>
                        handleCellChange(rowIndex, "expense", e.target.value)
                      }
                    />
                  )}
                </td>
                {Array.from({ length: academicsInfo.programDuration }).map(
                  (_, i) => (
                    <td key={i}>
                      {forView ? (
                        row[`Year${i + 1}`] || "-"
                      ) : (
                        <InputField
                          type="number"
                          className="col-sm-12 col-md-12 col-lg-12 my-2"
                          value={row[`Year${i + 1}`] || ""}
                          placeholder={`Enter Year ${i + 1}`}
                          onChange={e =>
                            handleCellChange(
                              rowIndex,
                              `Year${i + 1}`,
                              e.target.value,
                            )
                          }
                        />
                      )}
                    </td>
                  ),
                )}
                <td>
                  {forView ? (
                    row.total
                  ) : (
                    <InputField
                      type="number"
                      className="col-sm-12 col-md-12 col-lg-12 my-2"
                      value={row?.total}
                      placeholder="Enter Total Expense"
                      onChange={e =>
                        handleCellChange(rowIndex, "total", e.target.value)
                      }
                    />
                  )}
                </td>
              </tr>
            )) : null}
          </tbody>
        </Table>
      </div>

      {/* <InputField
        label="Education Expense"
        className="col-sm-12 col-md-6 col-lg-6 my-2"
        compulsory={true}
        disabled={forView}
        type="number"
        value={educationExpenseInfo.educationExpense}
        placeholder="Enter Education Expense"
        onChange={e =>
          handleChangeInfo(infoObject, "educationExpense", e.target.value)
        }
      />
      <InputField
        label="Other Expense"
        className="col-sm-12 col-md-6 col-lg-6 my-2"
        compulsory={true}
        disabled={forView}
        type="number"
        value={educationExpenseInfo.otherExpense}
        placeholder="Enter Other Expense"
        onChange={e =>
          handleChangeInfo(infoObject, "otherExpense", e.target.value)
        }
      /> */}
      {/* <TextareaField
        label="How did you learn about the Shukre Ilahi"
        className="col-sm-12 col-md-12 col-lg-12 my-2"
        compulsory={true}
        disabled={forView}
        rows="3"
        value={educationExpenseInfo.aboutShukreIlahi}
        placeholder="Write about Shukre Ilahi"
        onChange={e =>
          handleChangeInfo(infoObject, "aboutShukreIlahi", e.target.value)
        }
      /> */}
    </React.Fragment>
  )
}
