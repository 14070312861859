import React, { useEffect, useState } from "react"
import { Col, Row } from "reactstrap"
import ApplicationForm from "./application-form"
import ApplicationsTable from "./application-table"
import { IoChevronBack } from "react-icons/io5"
import html2pdf from "html2pdf.js"
import {
  allApplications,
  ApplicationFetch,
} from "redux/slice/applications/applicationSlice"
import { useSelector } from "react-redux"
import { pdfDownload } from "utilities/pdfDownload"
import { useDispatch } from "react-redux"
import { printData } from "utilities/printData"
import { apiError } from "store/actions"

export default function Applications() {
  const dispatch = useDispatch()
  const { singleApplicationDetail } = useSelector(allApplications)
  const [create, setCreate] = useState(false)
  const [applicationData, setApplicationsData] = useState({})
  const [updateId, setUpdateId] = useState("")
  const [readyToDownload, setReadyToDownload] = useState(false)
  const [readyToPrint, setReadyToPrint] = useState(false)

  const forUpdate = application_data => {
    console.log("for update staff", application_data)
    setUpdateId(application_data.id)
    // setApplicationsData(application_data)
    setCreate(true)
  }

  const toggleTab = () => {
    setUpdateId("")
    setApplicationsData({})
    setCreate(!create)
  }

  useEffect(() => {
    if (readyToDownload) {
      pdfDownload("application-form", "ApplicationForm")
      setReadyToDownload(false);
    }
    if (readyToPrint) {
      printData("application-form")
      setReadyToPrint(false);
    }
  }, [readyToDownload, readyToPrint])
  
  useEffect(() => {
    setApplicationsData(singleApplicationDetail)
  }, [singleApplicationDetail])

  const handleDownload = async _id => {
    console.log("id received", _id)
    try{
      let fetch = await dispatch(ApplicationFetch(_id))
      if (fetch?.payload?.status === 200) {
        // setApplicationsData(fetch.payload.data)
        setTimeout(()=>{
          setReadyToDownload(true)
        }, 1400)
      }
    }
    catch(err){
      console.log("error occur before started to downlaod..", err)
    }
  }

  const handlePrint = async (_id) => {
    try{
      let fetch = await dispatch(ApplicationFetch(_id))
      if (fetch?.payload?.status === 200) {
        // setApplicationsData(fetch.payload.data)
        setTimeout(()=>{
          setReadyToPrint(true);
        }, 1400)
      }
    }
    catch(err){
      console.log("error occur before started to print..", err);
    }
  }

  return (
    <div>
      <Row className="mb-4">
        <Col className="justify-content-end d-flex">
          {create ? (
            <button
              className="switch-btn"
              onClick={toggleTab}
            >
              <IoChevronBack /> Back
            </button>
          ) : null}
        </Col>
      </Row>
      {create ? (
        <ApplicationForm
          applicationData={singleApplicationDetail}
          updateId={updateId}
          toggleTab={toggleTab}
        />
      ) : (
        <ApplicationsTable
          forUpdate={forUpdate}
          handleDownload={handleDownload}
          handlePrint={handlePrint}
        />
      )}
      {!create && (
        <div style={{ display: "none" }}>
          <ApplicationForm
            applicationData={singleApplicationDetail}
            updateId={updateId}
            toggleTab={toggleTab}
          />
        </div>
      )}
    </div>
  )
}
