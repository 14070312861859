import React, { useEffect } from "react";
import C3Chart from "react-c3js";
import "c3/c3.css";
import { FaShoppingBag, FaUserPlus } from "react-icons/fa"; // Import icons as needed
import { IoIosStats } from "react-icons/io";
import { MdDownloading, MdCancel } from "react-icons/md";

const DonutChart = ({ reports }) => {

  const data = {
    columns: reports.map(report => [report.title, report.total]),
    type: "donut",
  };

  const donut = {
    // title: `Status wise count`,
    width: 25,
    label: { show: true }, // Use false instead of !1 for clarity
  };

  useEffect(()=>{
    console.log("reports in donut", reports)
    console.log("donuts in donut", donut)
  }, [reports, donut])

  const color = {
    // pattern: ["gray", "#28bbe3", "#0ca670", "#bf3939", "#ffcc00"], // Add more colors as needed
    pattern: ["#ffcc00", "#28bbe3", "#0ca670", "gray", "#bf3939"], // Add more colors as needed
  };

  const size = {
    height: 300,
  };

  return (
    <React.Fragment>
      <C3Chart
        data={data}
        donut={donut}
        color={color}
        size={size}
        dir="ltr"
      />
    </React.Fragment>
  );
};

export default DonutChart;
