import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Form,
  Alert,
  Input,
  FormFeedback,
} from "reactstrap"
import logoDark from "../../assets/images/logo.png"
import logoLight from "../../assets/images/logo.png"
import { useSelector, useDispatch } from "react-redux"
import PropTypes from "prop-types"
import * as Yup from "yup"
import { useFormik } from "formik"
import withRouter from "components/Common/withRouter"
import {
  LoginUser,
  verifyAuthenticatorCode,
  setApiResponseStutusToDefault,
  userData,
  sendAuthenticatorCode,
} from "redux/slice/user/userSlice"
import { SHUK_AUTH_PORTAL_TOKEN } from "redux/enums"
import { IoMdArrowRoundBack } from "react-icons/io"

const Login = props => {
  document.title = "Login | Shukre Ilahi"
  const { apiStatus, response, respFrom, error, loading } =
    useSelector(userData)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [showAuthenticatorCode, setShowAuthenticatorCode] = useState(false)
  const [resendEmail, setResendEmail] = useState("")

  useEffect(() => {
    if (apiStatus && !error && respFrom === "Login") {
      setShowAuthenticatorCode(true)
    }
  }, [apiStatus])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
      authcode: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: values => {
      if (showAuthenticatorCode) {
        console.log("email", values.email)
        console.log("authcode", values.authcode)
        dispatch(
          verifyAuthenticatorCode({
            email: values.email,
            twoFactorCode: values.authcode,
            twoFactorType: "Email",
          }),
        )
          .then(result => {
            console.log("response for token", result)
            if (result?.payload?.response?.status === 200) {
              navigate("/dashboard")
            } else {
              console.log("Invalid authentication code.")
            }
          })
          .catch(error => {
            console.log("error sending code", error)
          })
      } else {
        setResendEmail(values.email)
        const data = { userName: values.email, password: values.password }
        dispatch(setApiResponseStutusToDefault())
        dispatch(LoginUser(data))
          .then(result => {
            console.log("resultfromlogin", result)
            if (result?.payload?.response?.status === 200) {
              console.log("authentication code sending")
              dispatch(sendAuthenticatorCode(values.email))
            } else {
              console.log("Error sending code.")
            }
          })
          .catch(err => {
            console.log("Login error", err)
          })
      }
    },
  })

  const handleResend = () => {
    console.log("resending code...", resendEmail)
    dispatch(sendAuthenticatorCode(resendEmail))
  }

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <h3 className="text-center mt-5 mb-4">
                    <Link to="/" className="d-block auth-logo">
                      <img
                        src={logoDark}
                        alt=""
                        height="160"
                        className="auth-logo-dark"
                      />
                      <img
                        src={logoLight}
                        alt=""
                        height="70"
                        className="auth-logo-light"
                      />
                    </Link>
                  </h3>

                  <div className="p-3">
                    <h4 className="text-muted font-size-18 mb-1 text-center">
                      Welcome Back!
                    </h4>
                    <p className="text-muted text-center">
                      Sign in to continue to Shukre Ilahi.
                    </p>
                    {apiStatus && error && respFrom === "Login" ? (
                      <Alert color={"danger"}>{response}</Alert>
                    ) : apiStatus && !error && respFrom === "Login" ? (
                      <Alert color={"success"}>{response}</Alert>
                    ) : null}
                    {!showAuthenticatorCode ? (
                      <Form
                        className="form-horizontal mt-4"
                        onSubmit={validation.handleSubmit}
                      >
                        <div className="mb-3">
                          <Label htmlFor="username">Email</Label>
                          <Input
                            name="email"
                            type="email"
                            placeholder="Enter email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email}
                          />
                        </div>

                        <div className="mb-3">
                          <Label htmlFor="userpassword">Password</Label>
                          <Input
                            name="password"
                            type="password"
                            placeholder="Enter Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password}
                          />
                        </div>

                        <Row className="mb-3 mt-4">
                          <Col xs={6}>
                            <div className="form-check">
                              <Input
                                type="checkbox"
                                className="form-check-input"
                                id="customControlInline"
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="customControlInline"
                              >
                                Remember me
                              </Label>
                            </div>
                          </Col>
                          <Col xs={6} className="text-end">
                            <button
                              className="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                              disabled={
                                loading ||
                                !validation.values.email ||
                                !validation.values.password
                              }
                            >
                              {loading ? (
                                <div
                                  className="spinner-border text-light"
                                  role="status"
                                  style={{ width: "1.1rem", height: "1.1rem" }}
                                />
                              ) : (
                                "Log In"
                              )}
                            </button>
                          </Col>
                        </Row>
                      </Form>
                    ) : (
                      <Form
                        className="form-horizontal mt-4"
                        onSubmit={validation.handleSubmit}
                      >
                        <button
                          className="btn btn-outline-secondary mb-2"
                          onClick={() => setShowAuthenticatorCode(false)}
                        >
                          <IoMdArrowRoundBack size={20} />
                        </button>
                        <div className="mb-3">
                          <Label htmlFor="authenticatorCode">
                            Authentication Code
                          </Label>
                          <Input
                            name="authcode"
                            type="text"
                            placeholder="Enter Authenticator Code"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.authcode}
                          />
                        </div>
                        <Row className="mb-3 mt-4">
                          <Col xs={6}>
                            <Link
                              className="text-secondary"
                              onClick={handleResend}
                            >
                              Resend code
                            </Link>
                          </Col>
                          <Col xs={6} className="text-end">
                            <button
                              className="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                              disabled={!validation.values.authcode}
                            >
                              Verify Code
                            </button>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </div>
                </CardBody>
              </Card>

              <div className="mt-5 text-center">
                <p>
                  Don't have an account?{" "}
                  <Link to="/register" className="text-primary">
                    Signup Now
                  </Link>
                </p>
                © {new Date().getFullYear()} Shukre Ilahi - Developed by Happy
                Digital.
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
