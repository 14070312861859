import React, { Component } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import ReactApexChart from "react-apexcharts"

class MonthlyEarnings extends Component {
  constructor(props) {
    super(props)

    this.state = {
      options: {
        colors: ["#28bbe3", "#7A6FBE"],
        chart: {
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            columnWidth: "70%",
          },
        },
        grid: {
          borderColor: "#0ca670",
          row: {
            colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },

        xaxis: {
          categories: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "Octuber",
            "November",
            "December",
          ],
          labels: {
            formatter: function (val) {
              return val
            },
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          title: {
            text: undefined,
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val
            },
          },
        },
        fill: {
          opacity: 1,
        },

        legend: {
          show: false,
          position: "top",
          horizontalAlign: "left",
          offsetX: 40,
        },
      },
      series: [
        {
          name: "Donations",
          data: [45, 75, 100, 49, 100, 63, 50, 67, 50, 55, 93, 80],
        },
        {
          name: "Disbursements",
          data: [18, 46, 85, 79, 90, 29, 50, 65, 40, 35, 90, 61],
        },
      ],
    }
  }
  render() {
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <h4 className="card-title mb-4">Monthly Donations</h4>

            <Row className="text-center mt-4">
              <Col xs="6">
                <h5 className="font-size-20">
                  {[45, 75, 100, 49, 100, 63, 50, 67, 50, 55, 93, 80].reduce(
                    (p, n) => p + n,
                  )}
                </h5>
                <p className="text-muted">Current Year Donations</p>
              </Col>
              <Col xs="6">
                <h5 className="font-size-20">
                  {[18, 46, 85, 79, 90, 29, 50, 65, 40, 35, 90, 61].reduce(
                    (p, n) => p + n,
                  )}
                </h5>
                <p className="text-muted">Current Year Disbursements</p>
              </Col>
            </Row>

            <div
              id="morris-bar-stacked"
              className="morris-charts morris-charts-height"
              dir="ltr"
            >
              <ReactApexChart
                options={this.state.options}
                series={this.state.series}
                type="bar"
                height="290"
              />
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    )
  }
}

export default MonthlyEarnings
