import { axiosInstance, instanceAuth } from "api/apiConfig"

export const donationFetchAPI = async data => {
  try {
    console.log("Donation get params in api", data)
    const response = await instanceAuth.get("DonationMaster", {
      params: {
        ...data,
      },
    })
    return response
  } catch (error) {
    console.log("error fetching donation data...", error)
    throw error
  }
}

export const donationCreateAPI = async data => {
  console.log("Donation staff in helper", data)
  try {
    const response = await instanceAuth.post("DonationMaster", data)
    return response
  } catch (error) {
    console.log("error creating donation...", error)
    throw error
  }
}

export const donationUpdateAPI = async ({ id: id, data: data }) => {
  try {
    console.log(`Update donation in helper with id: ${id}`, data)
    const response = await instanceAuth.put(`DonationMaster/${id}`, data)
    return response
  } catch (error) {
    console.log("error updating donation...", error)
    throw error
  }
}

export const donationDeleteAPI = async id => {
  try {
    console.log(`Delete donation in helper with id: ${id}`)
    const response = await instanceAuth.delete(`DonationMaster/${id}`)
    return response
  } catch (error) {
    console.log("error deleting donation...", error)
    throw error
  }
}
