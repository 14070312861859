import { InputField } from "components/Common/input-fields"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"

export default function AcademicsInfoForm({
  tabs,
  updateTabComplete,
  handleChangeInfo,
  handleFileChange,
  handleFileRemove,
  academicsInfo,
  forView,
}) {
  let infoObject = "academics"

  return (
    <React.Fragment>
      <h5 className="mt-4 text-secondary-si section-heading page-break">Academics Details</h5>

      <InputField
        label="Institute Name"
        compulsory={true}
        disabled={forView}
        type="text"
        value={academicsInfo.instituteName}
        placeholder="Type Institute Name"
        onChange={e =>
          handleChangeInfo(infoObject, "instituteName", e.target.value)
        }
      />
      <InputField
        label="Affliate University"
        compulsory={true}
        disabled={forView}
        type="text"
        value={academicsInfo.affliatedUniversity}
        placeholder="Type School Address"
        onChange={e =>
          handleChangeInfo(infoObject, "affliatedUniversity", e.target.value)
        }
      />
      <InputField
        label="Institute Website"
        compulsory={true}
        disabled={forView}
        type="text"
        value={academicsInfo.instituteWebsite}
        placeholder="Type Institute Website"
        onChange={e =>
          handleChangeInfo(infoObject, "instituteWebsite", e.target.value)
        }
      />
      <InputField
        label="Institute Address"
        compulsory={true}
        disabled={forView}
        type="text"
        value={academicsInfo.instituteAddress}
        placeholder="Type Address"
        onChange={e => handleChangeInfo(infoObject, "instituteAddress", e.target.value)}
      />
      {/* <InputField
        label="Pin Code"
        compulsory={true}
        disabled={forView}
        type="text"
        value={academicsInfo.pinCode}
        placeholder="Type Pin Code"
        onChange={e => handleChangeInfo(infoObject, "pinCode", e.target.value)}
      />
      <InputField
        label="City"
        compulsory={true}
        disabled={forView}
        type="text"
        value={academicsInfo.city}
        placeholder="Type City"
        onChange={e => handleChangeInfo(infoObject, "city", e.target.value)}
      />
      <InputField
        label="Country"
        compulsory={true}
        disabled={forView}
        type="text"
        value={academicsInfo.country}
        placeholder="Type Country"
        onChange={e => handleChangeInfo(infoObject, "country", e.target.value)}
      /> */}
      <InputField
        label="Course of Study"
        compulsory={true}
        disabled={forView}
        type="text"
        value={academicsInfo.programName}
        placeholder="Enter Course Of Study"
        onChange={e =>
          handleChangeInfo(infoObject, "programName", e.target.value)
        }
      />
      <InputField
        label="Current Year of Study"
        compulsory={true}
        disabled={forView}
        type="text"
        value={academicsInfo.programCurrentYear}
        placeholder="Select Current Year of Study"
        onChange={e =>
          handleChangeInfo(infoObject, "programCurrentYear", e.target.value)
        }
      />
      <InputField
        label="Duration Of Course (In Years)"
        compulsory={true}
        disabled={forView}
        type="number"
        value={academicsInfo.programDuration}
        placeholder="Enter Duration Of Course"
        onChange={e =>
          handleChangeInfo(infoObject, "programDuration", e.target.value)
        }
      />
      <InputField
        label="Program Start Date"
        compulsory={true}
        disabled={forView}
        type="date"
        value={academicsInfo.programStartDate}
        placeholder="Enter Program Start Date"
        onChange={e =>
          handleChangeInfo(infoObject, "programStartDate", e.target.value)
        }
      />
    </React.Fragment>
  )
}
