import Notify from "components/Common/notify";

export const printData = (parentDivId) => {
  try {
    const printWindow = window.open("", "_blank");
    const formContent = document.getElementById(parentDivId).innerHTML;

    // all stylesheets
    const stylesheets = Array.from(document.styleSheets)
      .map((sheet) => {
        if (sheet.href) {
          // External stylesheet
          return `<link rel="stylesheet" href="${sheet.href}">`;
        } else if (sheet.ownerNode && sheet.ownerNode.innerHTML) {
          // Inline style blocks
          return `<style>${sheet.ownerNode.innerHTML}</style>`;
        }
        return "";
      })
      .join("");

    printWindow.document.open();
    printWindow.document.write(`
      <html>
      <head>
        <title>Print Application Form</title>
        ${stylesheets}
        <style>
          /* CSS for print layout */
          @media print {
            body {
              -webkit-print-color-adjust: exact; /* Ensures color accuracy */
              overflow: visible !important;
            }
            img, input, textarea {
              page-break-inside: avoid; /* Avoid breaking images or inputs across pages */
            }
            /* Prevents content splitting */
            .no-break {
              page-break-inside: avoid;
              page-break-before: auto;
              page-break-after: auto;
            }
          }
        </style>
      </head>
      <body>
        <div class="no-break">
          ${formContent} <!-- The form content to print -->
        </div>
      </body>
      </html>
    `);
    printWindow.document.close();

    printWindow.onload = () => {
      printWindow.focus();
      printWindow.print();
      printWindow.close();
    };
  } catch (error) {
    Notify("error", "Error Printing Document.");
    console.log("Error printing", error);
  }
};
