import { combineReducers, configureStore } from "@reduxjs/toolkit"
import allApplications from "redux/slice/applications/applicationSlice"
import allDonations from "redux/slice/donation/donationMaster"
import allStaffs from "redux/slice/staff/staffSlice"
import allStudents from "redux/slice/students/studentsSlice"
import userSlice from "redux/slice/user/userSlice"

const rootReducer = combineReducers({
  user: userSlice,
  allStaffs: allStaffs,
  allStudents: allStudents,
  allApplications: allApplications,
  allDonations: allDonations,
})

export const store = configureStore({
  reducer: rootReducer,
})
