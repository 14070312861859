import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Modal,
  Row,
  Table,
} from "reactstrap"
import {
  MdDelete,
  MdModeEdit,
  MdOutlineViewAgenda,
  MdFileDownload,
  MdOutlineAssignmentInd,
  MdQuestionMark,
  MdOutlinePrint,
  MdOutlineModeComment,
} from "react-icons/md"
import { useEffect, useState } from "react"
import {
  DataFilter,
  ItemsPerPage,
  SearchFilter,
} from "components/Common/filters"
import { useSelector, useDispatch } from "react-redux"
import PaginationComponent from "components/Common/pagination"
import Loader from "components/Common/loader"
import {
  allApplications,
  ApplicationDelete,
  AllApplicationFetch,
  ApplicationFetch,
} from "redux/slice/applications/applicationSlice"
import ApplicationAssignStaff from "./application-assign-staff"
import { DateFormater } from "utilities/dateformater"
import { allStaffs, StaffFetch } from "redux/slice/staff/staffSlice"

export default function ApplicationsTable(props) {
  const dispatch = useDispatch()
  const { applicationsData, loading, totalPages } = useSelector(allApplications)
  const { staffMembersData } = useSelector(allStaffs)
  const [itemsPerPage, setItemsPerPage] = useState(5)
  const [currentPage, setCurrentPage] = useState(1)
  const [selectedApplication, setSelectedApplication] = useState({})
  const [assignModalState, setAssignModalState] = useState(false)
  const [searchTerm, setSearchTerm] = useState("")
  const [filters, setFilters] = useState({
    status: "",
  })

  const paginate = pageNumber => {
    console.log("paginate call with page number: ", pageNumber)
    setCurrentPage(pageNumber)
    let data = {
      pageNumber: pageNumber,
      pageSize: itemsPerPage,
      Status: filters.status ? filters.status : null,
    }
    dispatch(AllApplicationFetch(data))
  }

  useEffect(() => {
    let data = {
      pageNumber: 1,
      pageSize: itemsPerPage,
      Status: filters.status ? filters.status : null,
    }
    dispatch(AllApplicationFetch(data))
    dispatch(StaffFetch({}))
  }, [itemsPerPage, filters])

  const handleItemsPerPage = perPage => {
    console.log("perPage", perPage)
    setItemsPerPage(Number(perPage))
  }

  const handleView = received => {
    console.log("view", received)
    props.forUpdate(received);
    dispatch(ApplicationFetch(received?.id));
  }

  const handleDownload = received => {
    console.log("download");
    props.handleDownload(received?.id);
  }

  const handleAssignModal = () => {
    // console.log("receive_ids table", receive_ids)
    setAssignModalState(!assignModalState)
  }

  const handleQueries = received => {
    console.log("queries", received)
  }

  const handlePrint = received => {
    console.log("print")
    props.handlePrint(received?.id);
  }

  const handleComments = received => {
    console.log("comments", received)
  }

  function calculateAge(dateOfBirth) {
    var today = new Date();
    var birthDate = new Date(dateOfBirth);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  return (
    <div>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody style={{ minHeight: "400px" }}>
              <CardTitle className="fs-2 dot-after">Applications</CardTitle>
              {/* Legend Section with Color Boxes */}
              {/* <div className="mb-3">
                <h6>Legend:</h6>
                <div className="d-flex align-items-center mb-2">
                  <span
                    className="badge bg-warning me-2"
                    style={{ width: "20px", height: "20px", display: "inline-block" }}
                  ></span>
                  <span>Not Eligible</span>
                </div>
                <div className="d-flex align-items-center">
                  <span
                    className="badge bg-success me-2"
                    style={{ width: "20px", height: "20px", display: "inline-block" }}
                  ></span>
                  <span>Eligible (age between 18 and 30)</span>
                </div>
              </div> */}
              <div
                className="d-flex justify-content-end flex-row align-items-center"
                style={{ width: "100%" }}
              >
                <div className="me-auto w-sm-100">
                {/* <h6>Legend:</h6> */}
                  <div className="d-flex align-items-center mb-2">
                    <span
                      className="badge me-2"
                      style={{ width: "15px", height: "15px", display: "inline-block", backgroundColor: "#FDF0D3" }}
                    ></span>
                    <span className="me-3 text-nowrap">Age Not Eligible</span>
                  </div>
                </div>
                <div className="me-3">
                  <SearchFilter
                    value={searchTerm}
                    label="Search Student"
                    onChange={e => {
                      setSearchTerm(e.target.value)
                    }}
                  />
                </div>
                <div className="me-3">
                  <DataFilter
                    options={[
                      { id: "Pending", name: "Pending Application" },
                      { id: "Process", name: "Process Application" },
                      { id: "Completed", name: "Completed Application" },
                      { id: "Hold", name: "Hold Application" },
                      { id: "Reject", name: "Reject Application" },
                    ]}
                    value={filters.status}
                    label="Application Status"
                    onChange={e => {
                      setFilters(prev => ({
                        ...prev,
                        status: e.target.value,
                      }))
                    }}
                  />
                </div>
                <div>
                  <ItemsPerPage
                    value={itemsPerPage}
                    handleItemsPerPage={handleItemsPerPage}
                  />
                </div>
              </div>

              <div className="table-responsive">
                {loading ? (
                  <Loader />
                ) : (
                  <Table className="table mb-0 table-bordered">
                    <thead>
                      <tr>
                        <th className="text-nowrap">Application ID</th>
                        <th className="text-nowrap">Submit Date</th>
                        <th className="text-nowrap">Student Name</th>
                        <th className="text-nowrap">College Name</th>
                        <th className="text-nowrap">Status</th>
                        <th className="text-nowrap">Action</th>
                        <th className="text-nowrap">Assign Staff</th>
                        <th className="text-nowrap">Review Comments</th>
                      </tr>
                    </thead>
                    <tbody>
                      {applicationsData.map((app, index) => {
                        // const age = calculateAge(date);
                        let rowClass = "";
                        let age = null;
                        if (app?.dateOfBirth) {
                          age = calculateAge(app.dateOfBirth);
                          if (age < 18 || age > 30) {
                            console.log("age", age)
                            rowClass = "table-warning";
                          }
                        }
                        return (
                        <tr key={app?.id} className={`${rowClass}`}>
                          {/* <td className="text-nowrap">{staff.id}</td> ["96fe0338-75b4-4551-b5ae-08dcf4dbc176", "358e0852-18fc-40c7-b5af-08dcf4dbc176"] */}
                          <td className="text-nowrap">{app?.applicationId}</td>
                          <td className="text-nowrap">{app?.submittedDate ? DateFormater(app?.submittedDate) : ""}</td>
                          <td className="text-nowrap">{app?.firstName + " " + app?.lastName}</td>
                          <td className="text-nowrap">{app?.instituteName}</td>
                          <td className="text-nowrap">{app?.applicationStatus}</td>
                          <td className="d-flex">
                            <MdOutlineViewAgenda
                              style={{ cursor: "pointer" }}
                              className="me-2 text-success"
                              onClick={() => handleView(app)}
                              size={20}
                            />
                            <MdFileDownload
                              style={{ cursor: "pointer" }}
                              className="me-2 text-info"
                              onClick={() => handleDownload(app)}
                              size={20}
                            />
                            <MdOutlineAssignmentInd
                              style={{ cursor: "pointer" }}
                              className="me-2 text-warning"
                              onClick={() => {
                                setSelectedApplication(app)
                                handleAssignModal()
                              }}
                              size={20}
                            />
                            <MdQuestionMark
                              style={{ cursor: "pointer" }}
                              className="me-2 text-danger"
                              onClick={() => handleQueries(app)}
                              size={20}
                            />
                            <MdOutlinePrint
                              style={{ cursor: "pointer" }}
                              className="me-2 text-secondary"
                              onClick={() => handlePrint(app)}
                              size={20}
                            />
                          </td>
                          <td className="text-nowrap">
                            {app?.staffAssigned && app?.staffAssigned.length > 0 ? app.staffAssigned.map((staffId, index) => {
                              const staffMember = staffMembersData.find(member => member.id === staffId);
                              return (
                                <span key={staffId}>
                                  {staffMember ? staffMember.firstName : 'Unknown'}
                                  {index < app.staffAssigned.length - 1 && ', '}
                                  {/* {', '} */}
                                </span>
                              );
                            }) : null}
                          </td>
                          <td className="text-nowrap">
                            <MdOutlineModeComment
                              style={{ cursor: "pointer" }}
                              className="text-success"
                              onClick={() => handleComments(app)}
                              size={18}
                            />
                          </td>
                        </tr>
                      )})}
                    </tbody>
                  </Table>
                )}
              </div>
            </CardBody>
            <CardFooter>
              <div className="d-flex justify-content-center justify-content-md-end justify-content-lg-end align-items-center flex-wrap">
                <div style={{ marginLeft: "4px" }}>
                  <PaginationComponent
                    totalPages={totalPages}
                    currentPage={currentPage}
                    paginate={paginate}
                  />
                </div>
              </div>
            </CardFooter>
          </Card>
        </Col>
      </Row>
      <Modal isOpen={assignModalState} centered>
        <ApplicationAssignStaff handleAssignModal={handleAssignModal} selectedApplication={selectedApplication} />
      </Modal>
    </div>
  )
}
