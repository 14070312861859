import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import {
  donationFetchAPI,
  donationCreateAPI,
  donationUpdateAPI,
  donationDeleteAPI,
} from "../../../api/donation/donationApiHelper"
import Notify from "components/Common/notify"

export const DonationFetch = createAsyncThunk(
  "Donation/DonationFetch",
  async data => {
    try {
      const response = await donationFetchAPI(data)
      const _response = {
        data: response?.data,
        status: response?.status,
      }
      return _response
    } catch (error) {
      Notify("error", "Failed to fetch Staff Data")
      throw Error("Failed to fetch Staff Data")
    }
  },
)
export const DonationCreate = createAsyncThunk(
  "Donation/DonationCreate",
  async data => {
    try {
      const response = await donationCreateAPI(data)
      console.log("create staff response", response)
      if (response?.status === 200 || response?.status === 201) {
        Notify("success", "Staff added successfully.")
        return response.data
      } else {
        Notify("error", "Failed to create staff member")
        throw Error("Failed to create staff member")
      }
    } catch (error) {
      Notify("error", "Failed to create staff member")
      throw Error("Failed to create staff member")
    }
  },
)
export const DonationUpdate = createAsyncThunk(
  "Donation/DonationUpdate",
  async ({ id: id, data: data }) => {
    try {
      const response = await donationUpdateAPI({ id: id, data: data })
      if (response?.status === 200) {
        Notify("success", "Staff member updated.")
        return response.data
      } else {
        Notify("error", "Failed to update staff member")
        throw Error("Failed to update staff member")
      }
    } catch (error) {
      Notify("error", "Failed to update staff member")
      throw Error("Failed to update staff member")
    }
  },
)
export const DonationDelete = createAsyncThunk(
  "Donation/DonationDelete",
  async id => {
    try {
      const response = await donationDeleteAPI(id)
      if (response?.status === 200) {
        Notify("success", "Staff member deleted.")
        return response.data
      } else {
        Notify("error", "Failed to delete staff member")
        throw Error("Failed to delete staff member")
      }
    } catch (error) {
      Notify("error", "Failed to delete staff member")
      throw Error("Failed to delete staff member")
    }
  },
)

const initialState = {
  donorMasterData: [
    {
      id: 1,
      firstName: "John",
      middleName: "A.",
      lastName: "Doe",
      spouseName: "Jane Doe",
      primaryEmail: "johndoe@example.com",
      secondaryEmail: "john.doe@secondary.com",
      primaryPhone: "+923001234567",
      secondaryPhone: "+923007654321",
      address: "123 Main St",
      country: "Pakistan",
      state: "Punjab",
      city: "Lahore",
      zipCode: "54000",
      boardMember: "Yes",
      comments: "Active board member since 2021",
    },
    {
      id: 2,
      firstName: "Alice",
      middleName: "B.",
      lastName: "Smith",
      spouseName: "Bob Smith",
      primaryEmail: "alice.smith@example.com",
      secondaryEmail: "alice.smith@secondary.com",
      primaryPhone: "+923002345678",
      secondaryPhone: "+923008765432",
      address: "456 Elm St",
      country: "Pakistan",
      state: "Sindh",
      city: "Karachi",
      zipCode: "75500",
      boardMember: "No",
      comments: "Attended several board meetings",
    },
    {
      id: 3,
      firstName: "Michael",
      middleName: "C.",
      lastName: "Johnson",
      spouseName: "Mary Johnson",
      primaryEmail: "michael.johnson@example.com",
      secondaryEmail: "m.johnson@secondary.com",
      primaryPhone: "+923003456789",
      secondaryPhone: "+923009876543",
      address: "789 Maple Ave",
      country: "Pakistan",
      state: "Khyber Pakhtunkhwa",
      city: "Peshawar",
      zipCode: "25000",
      boardMember: "Yes",
      comments: "Newly appointed board member",
    },
  ],
  totalPages: 1,
  loading: false,
  error: null,
}

export const DonationSlice = createSlice({
  name: "DonationSlice",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(DonationFetch.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(DonationFetch.fulfilled, (state, action) => {
        console.log("Donation fetch payload", action.payload?.data?.items)
        state.loading = false
        state.totalPages = action.payload?.data?.totalPages
        state.donorMasterData = action.payload?.data?.items
      })
      .addCase(DonationFetch.rejected, (state, action) => {
        console.log("error staff fetch", action.error)
        state.loading = false
        state.error = action.error.message
      })
    builder
      .addCase(DonationCreate.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(DonationCreate.fulfilled, (state, action) => {
        console.log("Donation create payload", action.payload)
        state.loading = false
        state.donorMasterData = [...state.donorMasterData, action.payload.data]
      })
      .addCase(DonationCreate.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
    builder
      .addCase(DonationUpdate.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(DonationUpdate.fulfilled, (state, action) => {
        state.loading = false
        console.log("Donation update payload", action.payload)
        if (action.payload) {
          state.donorMasterData = state.donorMasterData.map(st =>
            st.id === action.payload?.data?.id ? action.payload?.data : st,
          )
        }
      })
      .addCase(DonationUpdate.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
    builder
      .addCase(DonationDelete.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(DonationDelete.fulfilled, (state, action) => {
        state.loading = false
        console.log("Donation delete payload", action.payload)
        state.donorMasterData = state.donorMasterData.filter(
          i => i?.id !== action.payload?.data,
        )
      })
      .addCase(DonationDelete.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
  },
})

export const {} = DonationSlice.actions

export const allDonations = state => state.allDonations

export default DonationSlice.reducer
