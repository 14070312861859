import React, { useEffect } from "react"
// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

import logoDark from "../../assets/images/logo.png"
import logoLight from "../../assets/images/logo.png"

// action
import { registerUser, apiError } from "../../store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import { Link, useNavigate } from "react-router-dom"
import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap"
import {
  RegisterUser,
  setApiResponseStutusToDefault,
  userData,
} from "redux/slice/user/userSlice"
import AlertNotify from "components/Common/alert-notify"

const Register = props => {
  //meta title
  document.title = "Register | Shukre Ilahi"

  const { apiStatus, response, respFrom, error, loading } = useSelector(userData)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // useEffect(() => {
  //   alert(error, response)
  // }, [apiStatus])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      userName: "",
      email: "",
      confirmPassword: "",
      password: "",
      role: "Admin",
    },
    validationSchema: Yup.object({
      userName: Yup.string().required("Please Enter Your Username"),
      email: Yup.string().required("Please Enter Your Email"),
      confirmPassword: Yup.string().required("Please Confirm Your Password"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: values => {
      // dispatch(registerUser(values));
      const data = {
        data: values,
      }
      dispatch(setApiResponseStutusToDefault())
      dispatch(RegisterUser(data))
    },
  })

  // const selectAccountState = (state) => state.Account;
  // const AccountProperties = createSelector(
  //   selectAccountState,
  //   (account) => ({
  //     user: account.user,
  //     registrationError: account.registrationError,
  //     success: account.success
  //     // loading: account.loading,
  //   })
  // );

  // const {
  //   user,
  //   registrationError, success
  //   // loading
  // } = useSelector(AccountProperties);

  useEffect(() => {
    if (apiStatus) {
      apiStatus &&
        !error &&
        (() => {
          setTimeout(() => {
            dispatch(setApiResponseStutusToDefault())
            navigate("/login")
          }, 100)
        })()
    }
  }, [apiStatus])

  // useEffect(() => {
  //   success && setTimeout(() => navigate("/login"), 2000)
  // }, [success, navigate])

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <h3 className="text-center mt-5 mb-4">
                    <Link to="/" className="d-block auth-logo">
                      <img
                        src={logoDark}
                        alt=""
                        height="160"
                        className="auth-logo-dark"
                      />
                      <img
                        src={logoLight}
                        alt=""
                        height="160"
                        className="auth-logo-light"
                      />
                    </Link>
                  </h3>
                  <div className="p-3">
                    <h4 className="text-muted font-size-18 mb-1 text-center">
                      Registeration
                    </h4>
                    <p className="text-muted text-center">
                      Get your Shukre Ilahi account now.
                    </p>
                    <Form
                      className="form-horizontal mt-4"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      {/* {user && user ? (
                                        <Alert color="success">
                                          Register User Successfully
                                        </Alert>
                                      ) : null} */}

                      {apiStatus && error && respFrom === "Register" ? (
                        // <AlertNotify clr="danger" msg={response} />
                        <Alert color={"danger"} style={{ marginTop: "13px" }}>
                          {response}
                        </Alert>
                      ) : apiStatus && !error && respFrom === "Register" ? (
                        // <AlertNotify clr="success" msg={response} />
                        <Alert color={"success"} style={{ marginTop: "13px" }}>
                          {response}
                        </Alert>
                      ) : null}

                      <div className="mb-3">
                        <Label htmlFor="useremail">Email</Label>
                        <Input
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label htmlFor="userName">Username</Label>
                        <Input
                          name="userName"
                          type="text"
                          placeholder="Enter username"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.userName || ""}
                          invalid={
                            validation.touched.userName &&
                            validation.errors.userName
                              ? true
                              : false
                          }
                        />
                        {validation.touched.userName &&
                        validation.errors.userName ? (
                          <FormFeedback type="invalid">
                            {validation.errors.userName}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label htmlFor="userpassword">Password</Label>
                        <Input
                          name="password"
                          type="password"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ""}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label htmlFor="userpassword">Confirm Password</Label>
                        <Input
                          name="confirmPassword"
                          type="password"
                          placeholder="Confirm Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.confirmPassword || ""}
                          invalid={
                            validation.touched.confirmPassword &&
                            validation.errors.confirmPassword
                              ? true
                              : false
                          }
                        />
                        {validation.touched.confirmPassword &&
                        validation.errors.confirmPassword ? (
                          <FormFeedback type="invalid">
                            {validation.errors.confirmPassword}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3 row mt-4">
                        <div className="col-12 text-end">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                            disabled={loading || !validation.values.email || !validation.values.userName || !validation.values.password || !validation.values.confirmPassword}
                          >
                            {loading ? (
                              <div
                                className="spinner-border text-light"
                                style={{ height: "16px", width: "16px" }}
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            ) : (
                              "Register"
                            )}
                          </button>
                        </div>
                      </div>

                      <div className="mb-0 row">
                        <div className="col-12 mt-4">
                          <p className="text-muted mb-0 font-size-14">
                            By registering you agree to Shure Ilahi{" "}
                            <Link to="#" className="text-primary">
                              Terms of Use
                            </Link>
                          </p>
                        </div>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Already have an account ?{" "}
                  <Link to="/login" className="text-primary">
                    {" "}
                    Login{" "}
                  </Link>{" "}
                </p>
                © <script>
                  document.write(new Date().getFullYear())
                </script>{" "}
                Shukre Ilahi{" "}
                <span className="d-none d-sm-inline-block">
                  {" "}
                  - Developed with <i className="mdi mdi-heart text-danger"></i>{" "}
                  by Happy Digital.
                </span>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Register
