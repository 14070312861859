import { useEffect, useRef, useState } from "react"
import { FaExternalLinkAlt, FaEye, FaEyeSlash } from "react-icons/fa"
import { MdDelete } from "react-icons/md"
import { Modal, ModalBody, ModalHeader } from "reactstrap"

const InputField = ({
  label,
  compulsory,
  className,
  type,
  value,
  placeholder,
  disabled,
  onChange,
}) => {
  let regex
  let defaultValue = ""
  let errorMessage = ""
  if (type === "email") {
    // email validation regex if type = email
    regex =
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/
    errorMessage = "This is not a valid email address."
  } else if (type === "nic") {
    // cnic validation regex if type = nic
    regex = /^[0-9]{5}[0-9]{7}[0-9]$/
    type = "text"
    errorMessage = "NIC number should contain 13 digits."
  } else if (type === "tel") {
    // phone validation regex if type = tel
    regex = /^\+92\d{10}$/
    defaultValue = "+92"
    errorMessage = "Number should start with +92 and contain 10 digits onwards."
  } else {
    errorMessage = ""
  }

  const isValid = regex && value != defaultValue ? regex.test(value) : true

  return (
    <div className={className ? className : `col-sm-12 col-md-6 col-lg-4 my-2`}>
      {/* <form> */}
      <label>{label}</label>
      {compulsory ? <span className="text-danger">*</span> : null}
      {!disabled ? (
        <input
          className={`form-control text-black ${
            !isValid && !disabled ? "is-invalid" : ""
          }`}
          type={type}
          disabled={disabled}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          required
        />
      ) : (
        <p
          className="d-flex align-items-center input-view"
        >
          {value}
        </p>
      )}
      {!isValid && !disabled && <p className="text-danger">{errorMessage}</p>}
      {/* </form> */}
    </div>
  )
}

const TextareaField = ({
  label,
  compulsory,
  className,
  rows,
  value,
  placeholder,
  disabled,
  onChange,
}) => {
  return (
    <div className={className ? className : `col-sm-12 col-md-6 col-lg-4 my-2`}>
      <label>{label}</label>
      {compulsory ? <span className="text-danger">*</span> : null}
      {!disabled ? (
        <textarea
          className={`form-control text-black`}
          rows={rows}
          disabled={disabled}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          required
        />
      ) : (
        <p
          className="d-flex align-items-center input-view"
        >
          {value}
        </p>
      )}
    </div>
  )
}

const SelectField = ({
  label,
  compulsory,
  value,
  selectOptions,
  className,
  onChange,
  disabled,
}) => {
  return (
    <div
      className={`${
        className ? className : "col-sm-12 col-md-6 col-lg-4 my-2"
      }`}
    >
      <label>{label}</label>
      {compulsory ? <span className="text-danger">*</span> : null}
      {!disabled ? (
        <select
          className="form-select text-black"
          value={value}
          disabled={disabled}
          onChange={onChange}
        >
          <option value="" disabled>
            Select {label}
          </option>
          {selectOptions.map((option, index) => (
            <option key={index} option={option}>
              {option.toString()}
            </option>
          ))}
        </select>
      ) : (
        <p
          className="d-flex align-items-center input-view"
        >
          {value}
        </p>
      )}
    </div>
  )
}

const SelectFieldForId = ({
  label,
  compulsory,
  className,
  value,
  selectOptions,
  disabled,
  onChange,
}) => {
  let valueForView = ""
  if (disabled) {
    const selectedOption = selectOptions.find(option => option.id == value)
    valueForView = selectedOption?.name
  }
  return (
    <div
      className={`${
        className ? className : "col-sm-12 col-md-6 col-lg-4 my-2"
      }`}
    >
      <label>{label}</label>
      {compulsory ? <span className="text-danger">*</span> : null}
      {!disabled ? (
        <select
          className="form-select text-black"
          value={value}
          disabled={disabled}
          onChange={onChange}
        >
          <option value="" disabled>
            Select {label}
          </option>
          {selectOptions.map((option, index) => (
            <option key={index} option={option} value={option?.id}>
              {option?.name}
            </option>
          ))}
        </select>
      ) : (
        <p className="d-flex align-items-center input-view">{valueForView}</p>
      )}
    </div>
  )
}

const InputNumber = ({
  label,
  compulsory,
  className,
  type,
  value,
  step,
  disabled,
  min,
  max,
  placeholder,
  onChange,
}) => {
  return (
    <div
      className={`${
        className ? className : "col-sm-12 col-md-6 col-lg-4 my-2"
      }`}
    >
      <label>{label}</label>
      {compulsory ? <span className="text-danger">*</span> : null}
      {!disabled ? (
        <input
          className="form-control text-black"
          step={step}
          min={min}
          max={max}
          disabled={disabled}
          type={type}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
        />
      ) : (
        <p className="d-flex align-items-center input-view">{value}</p>
      )}
    </div>
  )
}

const PhoneInputField = ({
  label,
  compulsory,
  className,
  value,
  placeholder,
  disabled,
  onChange,
}) => {
  // Define the available extensions
  const extensions = ["+91", "+92", "+94", "+84"]

  const [extension, setExtension] = useState("+92")
  const [phoneNumber, setPhoneNumber] = useState("")

  const regex = /^\d{10}$/ // Phone number validation for 10 digits

  useEffect(() => {
    if (value) {
      const ext = value.substring(0, 3) // Extract the extension (first 3 chars)
      const number = value.substring(3) // Extract the phone number (remaining chars)

      setExtension(ext)
      setPhoneNumber(number)
    }
  }, [value])

  const handleExtensionChange = e => {
    setExtension(e.target.value)
    onChange(`${e.target.value}${phoneNumber}`)
  }

  const handlePhoneNumberChange = e => {
    const value = e.target.value
    setPhoneNumber(value)
    onChange(`${extension}${value}`)
  }

  const isValid = regex.test(phoneNumber)

  return (
    <div className={className ? className : `col-sm-12 col-md-6 col-lg-4 my-2`}>
      <label>{label}</label>
      {compulsory ? <span className="text-danger">*</span> : null}
      {!disabled ? (
        <div className="input-group">
          {/* Smaller select field for country extension */}
          <select
            className="form-select text-center fw-bold"
            value={extension}
            onChange={handleExtensionChange}
            disabled={disabled}
          >
            {extensions.map(ext => (
              <option key={ext} value={ext}>
                {ext}
              </option>
            ))}
          </select>

          {/* Larger input field for phone number */}
          <input
            className={`form-control col-9 ${
              !isValid && !disabled && value?.length > 3 ? "is-invalid" : ""
            }`} // Make the input larger
            type="tel"
            style={{ width: "50%" }}
            placeholder={placeholder}
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            disabled={disabled}
            required
          />
        </div>
      ) : (
        <p
          className="d-flex align-items-center input-view"
          style={{
            padding: "6px 12px",
            // border: "1px solid #7A6FBE",
            borderRadius: "8px",
          }}
        >{`${extension}${phoneNumber}`}</p>
      )}
      {!isValid && !disabled && value?.length > 3 && (
        <p className="text-danger">
          Phone number should contain exactly 10 digits.
        </p>
      )}
    </div>
  )
}

const PasswordInputField = ({
  label,
  compulsory,
  className,
  value,
  placeholder,
  disabled,
  onChange,
}) => {
  const [showPassword, setShowPassword] = useState(false) // State to toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  return (
    <div className={className ? className : `col-sm-12 col-md-6 col-lg-4 my-2`}>
      <label>{label}</label>
      {compulsory ? <span className="text-danger">*</span> : null}
      <div className="input-group">
        {!disabled ? (
          <input
            className="form-control text-black"
            type={showPassword ? "text" : "password"} // Toggles between text and password
            disabled={disabled}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            required
          />
        ) : (
          <p className="d-flex align-items-center input-view">{value}</p>
        )}
        {/* <div className="input-group-append"> */}
        <span
          className="input-group-text btn btn-outline-secondary"
          onClick={togglePasswordVisibility}
          style={{ cursor: "pointer", height: "100%" }}
        >
          {showPassword ? <FaEyeSlash size={18} /> : <FaEye size={18} />}{" "}
          {/* Icons for visibility */}
        </span>
        {/* </div> */}
      </div>
    </div>
  )
}

const InputImageField = ({
  label,
  compulsory,
  type,
  value,
  className,
  placeholder,
  disabled,
  onChange,
  handleFileRemove,
}) => {
  const inputRef = useRef(null)
  let imageUrl = null
  let fieldName = "No file chosen"

  if (value && value instanceof Array) {
    // console.log("value as array ", value);
    let filterValue = value.filter(item => item != undefined)
    if (filterValue.length > 0) {
      if (filterValue && filterValue[filterValue.length - 1] instanceof Blob) {
        imageUrl = URL.createObjectURL(filterValue[filterValue.length - 1])
        fieldName = filterValue[filterValue.length - 1].name
        if (fieldName.length > 18) {
          fieldName = fieldName.substring(0, 18) + "..."
        }
      } else {
        imageUrl = value
        fieldName = "File Selected"
      }
    }
  } else {
    imageUrl = null
    fieldName = "No file Chosen"
  }

  const handleRemoveFile = () => {
    handleFileRemove()
    if (inputRef.current) {
      inputRef.current.value = ""
    }
  }

  const openFileDialog = () => {
    document.getElementById(label).click()
  }
  // modal for image display
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)

  return (
    <div
      className={`${
        className ? className : "col-sm-12 col-md-6 col-lg-4 my-2"
      }`}
    >
      <label>{label}</label>
      {compulsory ? <span className="text-danger">*</span> : null}
      {!disabled ? (
        <div className="input-image-container" onClick={openFileDialog}>
          <div className="input-image-browse">Browse</div>
          <div className="input-image-name text-black poppins-regular">
            {fieldName}
          </div>
        </div>
      ) : !imageUrl ? (
        <p>No Image</p>
      ) : null}
      <input
        className="form-control text-black d-none"
        id={label}
        type="file"
        accept="image/*"
        disabled={disabled}
        placeholder={placeholder}
        onChange={onChange}
        ref={inputRef}
        required
      />
      <Modal isOpen={modal} toggle={toggle} size="lg" centered>
        <ModalHeader toggle={toggle}>{label}</ModalHeader>
        <ModalBody>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img
              className="img-fluid"
              src={imageUrl}
              alt="Uploaded File"
              style={{ width: "600px", height: "500px", objectFit: "contain" }}
            />
          </div>
        </ModalBody>
      </Modal>
      {imageUrl && (
        <div className="d-flex flex-column justify-content-center align-items-center mt-1">
          <img
            className="img-thumbnail"
            style={{ width: "120px", height: "120px", objectFit: "contain", textAlign: "center" }}
            src={imageUrl}
            alt="Uploaded File"
            onClick={toggle}
          />
          {!disabled ? (
            <button
              onClick={handleRemoveFile}
              className="btn btn-danger btn-sm"
              style={{ width: "35%" }}
            >
              <MdDelete size={15} />
            </button>
          ) : null}
        </div>
      )}
    </div>
  )
}

const InputDocField = ({
  label,
  compulsory,
  type,
  accept,
  value,
  className,
  placeholder,
  disabled,
  onChange,
  handleFileRemove,
}) => {
  const inputRef = useRef(null)
  let fileUrl = null
  let fieldName = "No file chosen"

  if (value && value instanceof Array) {
    // console.log("value as array ", value);
    let filterValue = value.filter(item => item != undefined)
    if (filterValue.length > 0) {
      if (filterValue && filterValue[filterValue.length - 1] instanceof Blob) {
        fileUrl = URL.createObjectURL(filterValue[filterValue.length - 1])
        fieldName = filterValue[filterValue.length - 1].name
        if (fieldName.length > 18) {
          fieldName = fieldName.substring(0, 18) + "..."
        }
      } else {
        fileUrl = value
        fieldName = "File Selected"
      }
    }
  } else {
    fileUrl = null
    fieldName = "No file Chosen"
  }

  const handleRemoveFile = () => {
    handleFileRemove()
    if (inputRef.current) {
      inputRef.current.value = ""
    }
  }

  const openFileDialog = () => {
    document.getElementById(label).click()
  }

  return (
    <div
      className={`${
        className ? className : "col-sm-12 col-md-6 col-lg-4 my-2"
      }`}
    >
      <label>{label}</label>
      {/* <label>{label}</label><MdDelete className="float-end text-danger" onClick={handleRemoveFile} /> */}
      {compulsory ? <span className="text-danger">*</span> : null}
      {!disabled ? (
        <div className="input-image-container" onClick={openFileDialog}>
          <div className="input-image-browse">Browse</div>
          <div className="input-image-name text-black poppins-regular">
            {fieldName}
          </div>
        </div>
      ) : !fileUrl ? (
        <p className="text-danger">File not uploaded</p>
      ) : null}
      <input
        className="form-control text-black d-none"
        id={label}
        type="file"
        accept={accept ? accept : ".pdf,.doc,.docx,.xls,.xlsx"}
        disabled={disabled}
        placeholder={placeholder}
        onChange={onChange}
        ref={inputRef}
        required
      />
      {fieldName == "File Selected" ? (
        <div>
          <a className="block" href={fileUrl} target="_blank">
            File Availaible <FaExternalLinkAlt size={10} />
          </a>
        </div>
      ) : null}
    </div>
  )
}

export {
  InputField,
  TextareaField,
  SelectField,
  SelectFieldForId,
  InputNumber,
  PhoneInputField,
  PasswordInputField,
  InputImageField,
  InputDocField,
}
