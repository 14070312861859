import React, { useEffect } from "react"
import { Table, Card, Badge, Button, CardFooter } from "reactstrap"
import { useNavigate } from "react-router-dom"

const LatestOrders = ({ applicationsData }) => {
  const navigate = useNavigate()

  useEffect(() => {
    console.log("applicationsData", applicationsData)
  }, [applicationsData])

  return (
    <React.Fragment>
      <Card>
        <div className="card-body" style={{ minHeight: "410px" }}>
          <h4 className="card-title mb-4">Latest Applications</h4>

          <div className="table-responsive">
            <Table className="align-middle table-centered table-vertical table-nowrap mb-1">
              <tbody>
                {applicationsData && applicationsData?.length > 0
                  ? applicationsData.slice(-5).map((app, key) => (
                      <tr key={key}>
                        <td className="text-nowrap">{app?.applicationId}</td>
                        <td className="text-nowrap">{app?.firstName}</td>
                        <td className="text-nowrap">
                          <Badge
                            className={`rounded-pill ${
                              app?.applicationStatus === "Pending"
                                ? "bg-warning"
                                : app?.applicationStatus === "Completed"
                                  ? "bg-success"
                                  : "bg-secondary"
                            }`}
                          >
                            {app?.applicationStatus}
                          </Badge>
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </Table>
          </div>
        </div>
        <CardFooter className="d-flex justify-content-center">
          <button
            className="btn btn-sm btn-primary"
            onClick={() => navigate("/applications")}
          >
            Show All
          </button>
        </CardFooter>
      </Card>
    </React.Fragment>
  )
}

export default LatestOrders
