import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import Notify from "components/Common/notify"
import {
  applicationsCreateAPI,
  applicationsDeleteAPI,
  allApplicationsFetchAPI,
  applicationsUpdateAPI,
  applicationFetchAPI,
  applicationsQuestions,
  applicationAssignStaffs,
  applicationAssignStaffsUpdate,
} from "api/applications/applicationsApiHelper"

export const AllApplicationFetch = createAsyncThunk(
  "Applications/AllApplicationFetch",
  async data => {
    try {
      const response = await allApplicationsFetchAPI(data)
      const _response = {
        data: response?.data,
        status: response?.status,
      }
      return _response
    } catch (error) {
      Notify("error", "Failed to fetch applications Data")
      throw Error("Failed to fetch applications Data")
    }
  },
)

export const ApplicationFetch = createAsyncThunk(
  "Applications/ApplicationFetch",
  async id => {
    try {
      const response = await applicationFetchAPI(id)
      const _response = {
        data: response?.data,
        status: response?.status,
      }
      return _response
    } catch (error) {
      Notify("error", "Failed to fetch applications Data")
      throw Error("Failed to fetch applications Data")
    }
  },
)

export const ApplicationCreate = createAsyncThunk(
  "Applications/ApplicationCreate",
  async data => {
    try {
      const response = await applicationsCreateAPI(data)
      console.log("create application response", response)
      if (response?.status === 200 || response?.status === 201) {
        Notify("success", "application added successfully.")
        return response.data
      } else {
        Notify("error", "Failed to create applications")
        throw Error("Failed to create applications")
      }
    } catch (error) {
      Notify("error", "Failed to create applications")
      throw Error("Failed to create applications")
    }
  },
)

export const ApplicationUpdate = createAsyncThunk(
  "Applications/ApplicationUpdate",
  async ({ id: id, data: data }) => {
    try {
      const response = await applicationsUpdateAPI({ id: id, data: data })
      if (response?.status === 200) {
        Notify("success", "application updated.")
        return response.data
      } else {
        Notify("error", "Failed to update application")
        throw Error("Failed to update application")
      }
    } catch (error) {
      Notify("error", "Failed to update application")
      throw Error("Failed to update application")
    }
  },
)

export const ApplicationDelete = createAsyncThunk(
  "Applications/ApplicationDelete",
  async id => {
    try {
      const response = await applicationsDeleteAPI(id)
      if (response?.status === 200) {
        Notify("success", "application deleted.")
        return response.data
      } else {
        Notify("error", "Failed to delete application")
        throw Error("Failed to delete application")
      }
    } catch (error) {
      Notify("error", "Failed to delete application")
      throw Error("Failed to delete application")
    }
  },
)

export const ApplicationQuestions = createAsyncThunk(
  "Applications/GetQuestions",
  async () => {
    try {
      const response = await applicationsQuestions()
      if (response?.status === 200) {
        let _response = {
          data: response?.data,
          status: response?.status
        }
        return _response
      } else {
        throw Error("Failed to get application questions")
      }
    } catch (error) {
      // Notify("error", "Failed to get application questions")
      throw Error("Failed to get application questions")
    }
  },
)

export const AssignStaff = createAsyncThunk(
  "Applications/ApplicationAssignStaff",
  async (data) => {
    try {
      const response = await applicationAssignStaffs(data)
      if (response?.status === 200) {
        let _response = {
          data: response?.data,
          status: response?.status,
          postData: data,
        }
        let message = response?.data?.message || "Staff Assign"
        Notify("success", message)
        return _response
      } else {
        Notify("error", "Failed to Assigned Staff")
        throw Error("Failed to Assigned Staff")
      }
    } catch (error) {
      Notify("error", "Failed to Assigned Staff")
      throw Error("Failed to Assigned Staff")
    }
  },
)

export const UpdateAssignStaff = createAsyncThunk(
  "Applications/UpdateAssignStaff",
  async (data) => {
    try {
      const response = await applicationAssignStaffsUpdate(data)
      if (response?.status === 200) {
        let _response = {
          data: response?.data,
          status: response?.status,
          postData: data,
        }
        let message = response?.data?.message || "Assign Staff Updated"
        Notify("success", message)
        return _response
      } else {
        Notify("error", "Failed to Assigned Staff")
        throw Error("Failed to Assigned Staff")
      }
    } catch (error) {
      Notify("error", "Failed to Assigned Staff")
      throw Error("Failed to Assigned Staff")
    }
  },
)

const initialState = {
  applicationsData: [],
  applicationsQuestions: [],
  singleApplicationDetail: {},
  totalPages: 0,
  loading: false,
  error: null,
}

export const ApplicationSlice = createSlice({
  name: "ApplicationSlice",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(AllApplicationFetch.pending, state => {
        state.loading = true
        state.singleApplicationDetail = {}
        state.error = null
      })
      .addCase(AllApplicationFetch.fulfilled, (state, action) => {
        console.log("all application fetch payload", action.payload?.data?.data?.items)
        state.loading = false
        state.totalPages = action.payload?.data?.data?.totalPages
        state.applicationsData = action.payload?.data?.data?.items
      })
      .addCase(AllApplicationFetch.rejected, (state, action) => {
        console.log("error all application fetch", action.error)
        state.loading = false
        state.error = action.error.message
      })
    builder
      .addCase(ApplicationFetch.pending, state => {
        state.loading = true
        state.error = null
        state.singleApplicationDetail = {};
      })
      .addCase(ApplicationFetch.fulfilled, (state, action) => {
        console.log("application fetch payload", action.payload)
        state.loading = false
        state.singleApplicationDetail = action.payload?.data?.studentInfo
      })
      .addCase(ApplicationFetch.rejected, (state, action) => {
        console.log("error application fetch", action.error)
        state.loading = false
        state.error = action.error.message
      })
    builder
      .addCase(ApplicationCreate.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(ApplicationCreate.fulfilled, (state, action) => {
        console.log("application create payload", action.payload)
        state.loading = false
        state.applicationsData = [
          ...state.applicationsData,
          action.payload.data,
        ]
      })
      .addCase(ApplicationCreate.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
    builder
      .addCase(ApplicationUpdate.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(ApplicationUpdate.fulfilled, (state, action) => {
        state.loading = false
        console.log("application update payload", action.payload)
        if (action.payload) {
          state.applicationsData = state.applicationsData.map(st =>
            st.id === action.payload?.data?.id ? action.payload?.data : st,
          )
        }
      })
      .addCase(ApplicationUpdate.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
    builder
      .addCase(ApplicationDelete.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(ApplicationDelete.fulfilled, (state, action) => {
        state.loading = false
        console.log("application delete payload", action.payload)
        state.applicationsData = state.applicationsData.filter(
          i => i?.id !== action.payload?.data,
        )
      })
      .addCase(ApplicationDelete.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
    builder
      .addCase(ApplicationQuestions.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(ApplicationQuestions.fulfilled, (state, action) => {
        state.loading = false
        console.log("application questions payload", action.payload?.data?.items)
        state.applicationsQuestions = action.payload?.data?.items
      })
      .addCase(ApplicationQuestions.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
    builder
      .addCase(AssignStaff.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(AssignStaff.fulfilled, (state, action) => {
        state.loading = false;
        console.log("staff assigning payload", action.payload);
        
        const application = state.applicationsData.find(
          app => app?.id === action.payload?.postData?.studentId,
        );
      
        if (application) {
          application.staffAssigned = action.payload?.postData?.staffIds;
        }      
      })
      .addCase(AssignStaff.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
    builder
      .addCase(UpdateAssignStaff.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(UpdateAssignStaff.fulfilled, (state, action) => {
        state.loading = false;
        console.log("staff assigning update payload", action.payload);
        
        const application = state.applicationsData.find(
          app => app?.id === action.payload?.postData?.studentId,
        );
      
        if (application) {
          application.staffAssigned = action.payload?.postData?.staffIds;
        }      
      })
      .addCase(UpdateAssignStaff.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
  },
})

export const {} = ApplicationSlice.actions

export const allApplications = state => state.allApplications

export default ApplicationSlice.reducer
