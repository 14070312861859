import { InputField, PasswordInputField, PhoneInputField, SelectFieldForId } from "components/Common/input-fields"
import Notify from "components/Common/notify"
import { useEffect, useState } from "react"
import Flatpickr from "react-flatpickr"
import { useSelector, useDispatch } from "react-redux"
import { StaffCreate, StaffUpdate } from "redux/slice/staff/staffSlice"

export default function StaffForm(props) {
  const dispatch = useDispatch()
  let passwordRegex = /^(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{6,}$/
  let mobileRegex = /^\+\d{12}$/
  let emailRegex =
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/
  const [error, setError] = useState("")
  const [createData, setCreateData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    role: "",
    email: "",
    userName: "",
    phoneNumber: "+92",
    dateOfBirth: "",
    password: "",
    confirmPassword: "",
  })

  useEffect(() => {
    console.log("for update as a prop ", props?.staffData)
    if (props?.staffData && Object.keys(props?.staffData).length !== 0) {
      setCreateData(props?.staffData)
    }
  }, [])

  const handleChangeInfo = (fieldName, value) => {
    console.log(fieldName, "change to", value)
    setCreateData(prevState => ({
      ...prevState,
      [fieldName]: value,
    }))
  }

  const handleSubmit = () => {
    console.log("createdata", createData)
    if (
      !createData.firstName ||
      // !createData.middleName ||
      // !createData.lastName ||
      !createData.role ||
      !createData.email ||
      !createData.phoneNumber ||
      !createData.dateOfBirth
    ) {
      Notify("error", "Fill al the fields required fields.")
    } else if (
      (!createData.password || !createData.confirmPassword) &&
      !props?.staffData &&
      Object.keys(props?.staffData).length === 0
    ) {
      Notify("error", "Please fill passwords fields")
    } else if (!emailRegex.test(createData.email)) {
      Notify("error", "Email address is not valid")
    } else if (!mobileRegex.test(createData.phoneNumber)) {
      Notify(
        "error",
        "Number should start with +92 and contain 10 digits onwards.",
      )
    } else if (
      !passwordRegex.test(createData.password) &&
      !(
        props?.staffData &&
        Object.keys(props?.staffData).length !== 0 &&
        !createData.password
      )
    ) {
      Notify(
        "error",
        "Password should be atleast 6 charcters long and contain special character.",
      )
    } else if (
      createData.password !== createData.confirmPassword &&
      !(
        props?.staffData &&
        Object.keys(props?.staffData).length !== 0 &&
        !createData.password &&
        !createData.confirmPassword
      )
    ) {
      Notify("error", "Password & confirm password not matched.")
    } else {
      if (props.staffData && Object.keys(props.staffData).length !== 0) {
        const modifiedData = { ...createData }
        if (!modifiedData.password || !modifiedData.confirmPassword) {
          modifiedData.password = null
          modifiedData.confirmPassword = null
        }
        dispatch(StaffUpdate({ id: props.updateId, data: modifiedData }))
        props.toggleTab()
      } else {
        dispatch(StaffCreate(createData))
        props.toggleTab()
        // dispatch(StaffCreate({id: props?.updateId, data: createData}))
      }
    }
  }

  return (
    <div className="card p-2">
      <div className="card-title fs-3 mt-2 mx-4">Create Staff</div>
      <div className="card-body">
        <div className="row">
          <InputField
            label="First Name"
            placeholder="Enter First Name"
            className="col-sm-12 col-md-6 col-lg-4 my-2"
            compulsory={true}
            disabled={false}
            type="text"
            value={createData.firstName}
            onChange={e => handleChangeInfo("firstName", e.target.value)}
          />
          <InputField
            label="Middle Name"
            placeholder="Enter Middle Name"
            className="col-sm-12 col-md-6 col-lg-4 my-2"
            compulsory={false}
            disabled={false}
            type="text"
            value={createData.middleName}
            onChange={e => handleChangeInfo("middleName", e.target.value)}
          />
          <InputField
            label="Last Name"
            placeholder="Enter Last Name"
            className="col-sm-12 col-md-6 col-lg-4 my-2"
            compulsory={false}
            disabled={false}
            type="text"
            value={createData.lastName}
            onChange={e => handleChangeInfo("lastName", e.target.value)}
          />
          <SelectFieldForId
            label="Select User Type"
            className="col-sm-12 col-md-6 col-lg-4 my-2"
            compulsory={true}
            disabled={false}
            selectOptions={[
              { id: "Admin", name: "Admin" },
              { id: "Review Committee", name: "Review Committee" },
              { id: "Board Member", name: "Board Member" },
            ]}
            value={createData.role}
            onChange={e => handleChangeInfo("role", e.target.value)}
          />
          <InputField
            label="Email"
            placeholder="Enter Email"
            className="col-sm-12 col-md-6 col-lg-4 my-2"
            compulsory={true}
            disabled={false}
            type="email"
            value={createData.email}
            onChange={e => {
              handleChangeInfo("email", e.target.value)
              handleChangeInfo("userName", e.target.value)
            }}
          />
          <PhoneInputField
            label="Phone Number"
            placeholder="Enter phone number"
            className="col-sm-12 col-md-6 col-lg-4 my-2"
            compulsory={true}
            disabled={false}
            value={createData.phoneNumber}
            onChange={value => handleChangeInfo("phoneNumber", value)}
          />
          {/* <div className="col-sm-12 col-md-6 col-lg-4 my-2">
            <label>Date of Birth</label>
            <Flatpickr
              className="form-control form-control-lg d-block"
              placeholder="dd M,yyyy"
              options={{
                altInput: true,
                altFormat: "F j, Y",
                dateFormat: "Y-m-d",
              }}
              value={createData.dateOfBirth}
              onChange={e => handleChangeInfo("dateOfBirth", e.target.value)}
            />
          </div> */}
          <InputField
            label="Date of Birth"
            placeholder="Select Date of Birth"
            className="col-sm-12 col-md-6 col-lg-4 my-2"
            compulsory={true}
            disabled={false}
            type="date"
            value={createData.dateOfBirth}
            onChange={e => handleChangeInfo("dateOfBirth", e.target.value)}
          />
          <PasswordInputField
            label="Password"
            placeholder="Enter Password"
            className="col-sm-12 col-md-6 col-lg-4 my-2"
            compulsory={true}
            disabled={false}
            value={createData.password}
            onChange={e => handleChangeInfo("password", e.target.value)}
          />
          <PasswordInputField
            label="Confirm Password"
            placeholder="Enter Confirm Password"
            className="col-sm-12 col-md-6 col-lg-4 my-2"
            compulsory={true}
            disabled={false}
            value={createData.confirmPassword}
            onChange={e => handleChangeInfo("confirmPassword", e.target.value)}
          />
        </div>
        <div className="d-flex justify-content-end mt-4">
          <button className="btn btn-danger me-2" onClick={()=>props.toggleTab()}>
            Cancel
          </button>
          <button className="btn btn-primary" onClick={handleSubmit}>
            {props.staffData && Object.keys(props.staffData).length !== 0
              ? "Update"
              : "Create"}
          </button>
        </div>
      </div>
    </div>
  )
}
