import { axiosInstance, instanceAuth } from "api/apiConfig"

export const studentsFetchAPI = async data => {
  try {
    console.log("Fetch students in helper", data)
    const response = await instanceAuth.get("StudentApplication/ApplicationData", {
      params: {
        ...data,
      },
    })
    return response
  } catch (error) {
    console.log("error fetching students data...", error)
    throw error
  }
}
