const ItemsPerPage = props => {
  return (
    <div className="form-floating mb-3">
      <select
        className="form-select"
        id="itemsPerPageSelect"
        value={props.value}
        onChange={e => props.handleItemsPerPage(e.target.value)}
      >
        <option value={0} disabled>
          Items per Page
        </option>
        <option value={5}>5</option>
        <option value={10}>10</option>
        <option value={20}>20</option>
        <option value={50}>50</option>
      </select>
      <label htmlFor="itemsPerPageSelect" className="w-100 text-ellipsis text-secondary">
        Items per page
      </label>
    </div>
  )
}
const DataFilter = ({ label, options, value, onChange, style, ...props }) => {
  return (
    <div className="form-floating mb-3" style={{ ...style }}>
      <select
        className="form-select"
        id="dataFilterSelect"
        value={value}
        onChange={onChange}
        {...props}
      >
        {/* <option key="" value="">
          All
        </option> */}
        {options?.map(option => (
          <option key={option?.id} value={option?.id}>
            {option?.name}
          </option>
        ))}
      </select>
      <label htmlFor="dataFilterSelect" className="w-100 text-ellipsis text-secondary">
        {label}
      </label>
    </div>
  )
}
const SearchFilter = ({ label, value, onChange, style, ...props }) => {
  return (
    <div className="form-floating mb-3" style={{ ...style }}>
      <input
        className="form-control"
        id="dataSearch"
        placeholder="Type to Search"
        type="text"
        value={value}
        onChange={onChange}
        {...props}
      />
      <label htmlFor="dataSearch" className="w-100 text-ellipsis text-secondary">
        {label}
      </label>
    </div>
  )
}
export { ItemsPerPage, DataFilter, SearchFilter }
