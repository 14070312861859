import React, { useEffect } from "react"
import { Card, CardBody, Row, CardTitle } from "reactstrap"
import DonutChart from "../AllCharts/DonutChart"

const MonthlyEarnings = props => {
  useEffect(()=>{
    console.log("props", props)
  }, [])
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="h4 mb-4">Applications</CardTitle>

          <Row className="text-center mt-4 d-flex justify-content-center flex-wrap">
            <div className="col-sm-12 col-md-6">
              <h5 className="font-size-20">{props?.statusCounts?.Completed}</h5>
              <p className="text-muted">Accepted Applications</p>
            </div>
            <div className="col-sm-12 col-md-6">
              <h5 className="font-size-20">{props?.statusCounts?.Reject}</h5>
              <p className="text-muted">Rejected Applications</p>
            </div>
          </Row>
          <div dir="ltr">
            <DonutChart reports={props?.reports} statusCounts={props?.statusCounts} />
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default MonthlyEarnings
