import {
  InputField,
  PasswordInputField,
  PhoneInputField,
  SelectFieldForId,
} from "components/Common/input-fields"
import Notify from "components/Common/notify"
import { useEffect, useState } from "react"
import Flatpickr from "react-flatpickr"
import { useSelector, useDispatch } from "react-redux"
import { StaffCreate, StaffUpdate } from "redux/slice/staff/staffSlice"
import PersonalInfoForm from "./formSections/personal"
import AcademicsInfoForm from "./formSections/academics"
import EducationExpensesInfoForm from "./formSections/education-expenses"
import AuthorizationInfoForm from "./formSections/authorization"
import ReferenceInfoForm from "./formSections/reference"
import DocumentsInfoForm from "./formSections/documents"
import { allApplications, ApplicationQuestions } from "redux/slice/applications/applicationSlice"
import { MapDataToApplicationState } from "utilities/mapDataToApplicationState"
import Loader from "components/Common/loader"

export default function ApplicationForm(props) {
  const dispatch = useDispatch()
  const { singleApplicationDetail, loading, applicationsQuestions } = useSelector(allApplications)
  let passwordRegex = /^(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{6,}$/
  let mobileRegex = /^\+\d{12}$/
  let emailRegex =
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/
  const [forView, setForView] = useState(true)
  const [formDataState, setFormDataState] = useState({
    // personal details
    personal: {
      firstName: "",
      middleName: "",
      lastName: "",
      dateOfBirth: "",
      email: "",
      phoneNumber: "+92",
      fatherFirstName: "",
      fatherMiddleName: "",
      fatherLastName: "",
      motherFirstName: "",
      motherMiddleName: "",
      motherLastName: "",
      homeAddress: "",
      alternateAddress: "",
      postalCode: "",
      alternatePhoneNumber: "",
      country: "",
      state: "",
      city: "",
      fatherOccupation: "",
      motherOccupation: "",
      annualIncome: "",
      houseStatus: "",
      totalHouseholdMembers: "",
      householdDetails: [{ name: "", age: "", relationship: "", occupation: "", annualIncome: "" }],
    },
    // accademic info
    academics: {
      instituteName: "",
      affliatedUniversity: "",
      instituteWebsite: "",
      instituteAddress: "",
      city: "",
      pinCode: "",
      country: "",
      programName: "",
      programCurrentYear: "",
      programDuration: "",
      programStartDate: "",
      instituteAdditionalInfo: "",
    },
    // education epenses
    educationExpenses: {
      currency: "",
      expenseList: [
        {
          expense: "Tuition Expenses",
          total: 40000,
          Year1: 10000,
          Year2: 10000,
          Year3: 10000,
          Year4: 10000,
        },
      ],
      educationExpense: "",
      otherExpense: "",
      aboutShukreIlahi: "",
    },
    // documents details
    documents: {
      nicImage: [],
      passportSizePicture: [],
      addmissionLettter: [],
      tutionFeeSchedule: [],
      marksheet: [],
      incomeVerification: [],
      feeReceiptsImage: [],
      miscellaneousExpense: [],
      otherDocuments: [],
    },
    // reference details
    refernce: {
      refernceDetails: [
        {
          name: "",
          email: "",
          phoneNumber: "",
          relationshipWithApplicant: "",
          coverLetterImageUrl: "",
        }
      ],
    },
    // authorization details
    authorization: {
      answers: [],
      applicationConsideration: "",
      signature: [],
      place: "",
      submittedDate: "",
      ipAddress: "",
    },
  })

  // const [formDataState, setFormDataState] = useState({
  //   // personal details
  //   personal: {
  //     firstName: "Ali",
  //     middleName: "Ahmed",
  //     lastName: "Khan",
  //     dateOfBirth: "1995-05-15",
  //     email: "ali.khan@example.com",
  //     phoneNumber: "+923001234567",
  //     fatherFirstName: "Mohammad",
  //     fatherMiddleName: "Ali",
  //     fatherLastName: "Khan",
  //     motherFirstName: "Fatima",
  //     motherMiddleName: "Bibi",
  //     motherLastName: "Khan",
  //     homeAddress: "123 Main Street, Karachi",
  //     alternateAddress: "456 Secondary Street, Karachi",
  //     postalCode: "75500",
  //     alternatePhoneNumber: "+923007654321",
  //     country: "Pakistan",
  //     state: "Sindh",
  //     city: "Karachi",
  //     fatherOccupation: "Engineer",
  //     motherOccupation: "Teacher",
  //     annualIncome: 2000,
  //     houseStatus: "owned",
  //     totalHouseholdMembers: 3,
  //     householdDetails: [
  //       {
  //         name: "Mariya",
  //         age: "19",
  //         relationship: "Daughter",
  //         occupation: "Student",
  //         annualIncome: 100,
  //       },
  //     ],
  //   },
  //   // academic info
  //   academics: {
  //     instituteName: "Karachi University",
  //     affliatedUniversity: "University of Karachi",
  //     instituteWebsite: "http://www.uok.edu.pk",
  //     instituteAddress: "University Road, Karachi",
  //     city: "Karachi",
  //     pinCode: "75270",
  //     country: "Pakistan",
  //     programName: "Computer Science",
  //     programCurrentYear: "3",
  //     programDuration: "4 years",
  //     programStartDate: "2025-05-15",
  //     instituteAdditionalInfo: "This is best institute for science subjects",
  //   },
  //   // education expenses
  //   educationExpenses: {
  //     currency: "PKR",
  //     expenseList: [
  //       {
  //         expense: "Tuition Expenses",
  //         total: 40000,
  //         Year1: 10000,
  //         Year2: 10000,
  //         Year3: 10000,
  //         Year4: 10000,
  //       },
  //     ],
  //     educationExpense: 1000,
  //     otherExpense: 500,
  //     aboutShukreIlahi:
  //       "ShukreIlahi is a scholarship program aimed at supporting students in need.",
  //   },
  //   // documents details
  //   documents: {
  //     nicImage: ["nic_image.jpg"],
  //     passportSizePicture: ["passport_photo.jpg"],
  //     addmissionLettter: ["admission_letter.pdf"],
  //     tutionFeeSchedule: ["tuition_fee_schedule.pdf"],
  //     marksheet: ["marksheet.pdf"],
  //     incomeVerification: ["income_verification.pdf"],
  //     feeReceiptsImage: ["boarding_bills.pdf"],
  //     miscellaneousExpense: ["misc_expense.pdf"],
  //     otherDocuments: ["other_docs.pdf"],
  //   },
  //   // reference details
  //   refernce: {
  //     refernceDetails: [
  //       {
  //         firstName: "Sarah",
  //         middleName: "A.",
  //         lastName: "Khan",
  //         contactNo: "+92 300 1122334",
  //         alternateContactNo: "+92 300 2233445",
  //         email: "sarah.khan@example.com",
  //         address: "456 Elm Street",
  //         city: "Karachi",
  //         country: "Pakistan",
  //         pinCode: "75270",
  //         relation: "Professor",
  //       },
  //     ],
  //   },
  //   // authorization details
  //   authorization: {
  //     applicationConsideration:
  //       "I hereby authorize the verification of the information provided.",
  //     signature: [
  //       "https://www.shutterstock.com/image-vector/handwritten-signature-signed-papers-documents-260nw-2248268539.jpg",
  //     ],
  //     place: "Karachi",
  //     submittedDate: "2024-10-16",
  //     ipAddress: "192.168.1.1",
  //   },
  // })

  useEffect(()=>{
    console.log("loading application details......")
    if (props?.applicationData && Object.keys(props?.applicationData).length !== 0 && !loading) {
      let transformedData = MapDataToApplicationState(props?.applicationData);
      console.log("singleApplicationDetail", transformedData)
      setFormDataState(transformedData);
    }
  }, [props?.applicationData])

  useEffect(()=>{
    console.log("application questions")
    dispatch(ApplicationQuestions())
  }, [])

  const handleChangeInfo = (parentObjectName, fieldName, value) => {
    console.log(fieldName, "change to", value)
    setFormDataState(prevState => ({
      ...prevState,
      [parentObjectName]: {
        ...prevState[parentObjectName],
        [fieldName]: value,
      },
    }))
  }

  const handleFileChange = (parentObjectName, fieldName, event) => {
    const file = event.target.files[0]
    console.log(fieldName, "change to", file)
    setFormDataState(prevState => ({
      ...prevState,
      [parentObjectName]: {
        ...prevState[parentObjectName],
        [fieldName]: [...prevState[parentObjectName][fieldName], file],
      },
    }))
  }

  const handleFileRemove = (parentObjectName, fieldName) => {
    console.log(parentObjectName, "->", fieldName, "remove")
    setFormDataState(prevState => ({
      ...prevState,
      [parentObjectName]: {
        ...prevState[parentObjectName],
        [fieldName]: [],
      },
    }))
  }

  const handleSubmit = () => {}

  return (
    <div className="card p-2" id="application-form">
      <div className="card-title fs-3 my-2 mx-4">Application Details</div>
      <div className="card-body" style={{minHeight: "500px"}}>
        {
          loading ? <Loader /> :
          <div className="row">
            <PersonalInfoForm
              handleChangeInfo={handleChangeInfo}
              personalInfo={formDataState.personal}
              forView={forView}
            />
            <AcademicsInfoForm
              handleChangeInfo={handleChangeInfo}
              academicsInfo={formDataState.academics}
              forView={forView}
            />
            <EducationExpensesInfoForm
              handleChangeInfo={handleChangeInfo}
              educationExpenseInfo={formDataState.educationExpenses}
              academicsInfo={formDataState.academics}
              forView={forView}
            />
            <DocumentsInfoForm
              handleChangeInfo={handleChangeInfo}
              handleFileChange={handleFileChange}
              handleFileRemove={handleFileRemove}
              documentsInfo={formDataState.documents}
              forView={forView}
            />
            <ReferenceInfoForm
              handleChangeInfo={handleChangeInfo}
              community={formDataState.refernce}
              forView={forView}
            />
            <AuthorizationInfoForm
              handleChangeInfo={handleChangeInfo}
              handleFileChange={handleFileChange}
              handleFileRemove={handleFileRemove}
              authorizationInfo={formDataState.authorization}
              forView={forView}
            />
          </div>
        }
      </div>
    </div>
  )
}
