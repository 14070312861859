import React from "react"
import { Navigate } from "react-router-dom"
import { SHUK_AUTH_PORTAL_TOKEN } from "redux/enums"

const Authmiddleware = props => {
  if (!localStorage.getItem(SHUK_AUTH_PORTAL_TOKEN)) {
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    )
  }
  return <React.Fragment>{props.children}</React.Fragment>
}

export default Authmiddleware
