import Notify from "components/Common/notify"
import html2pdf from "html2pdf.js"

export const pdfDownload = (parentDivId, fileName) => {
  try {
    if (parentDivId && fileName) {
      console.log(`Downloading File ${fileName}.pdf with id ${parentDivId}`)
      const element = document.getElementById(parentDivId)

      const loadImages = () => {
        const images = element.querySelectorAll("img")
        return Promise.all(
          Array.from(images).map(img => {
            return new Promise(resolve => {
              if (img.complete) {
                resolve()
              } else {
                img.onload = resolve
                img.onerror = resolve
              }
            })
          }),
        )
      }
      loadImages().then(() => {
        const options = {
          margin: 0.5,
          filename: `${fileName}.pdf`,
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2, useCORS: true },
          jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
        }

        html2pdf().from(element).set(options).save()
      })
    } else {
      console.log("Please provide id and file name! Thanks. status: 507")
    }
  } catch (error) {
    Notify("error", "Error Downloading File.");
    console.log("error downloading", error)
  }
}
