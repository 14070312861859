import React, { Component } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import ReactApexChart from "react-apexcharts"

class MonthlyEarnings extends Component {
  constructor(props) {
    super(props)

    this.state = {
      options: {
        colors: ["#ccc", "#7a6fbe", "rgb(40, 187, 227)"],
        chart: {
          toolbar: {
            show: !1,
          },
        },
        dataLabels: {
          enabled: !1,
        },
        stroke: {
          curve: "smooth",
          width: 0.1,
        },
        grid: {
          borderColor: "#f8f8fa",
          row: {
            colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: [
            "2011",
            "2012",
            "2013",
            "2014",
            "2015",
            "2016",
            "2017",
            "2018",
            "2019",
            "2020",
            "2021",
            "2022",
            "2023",
            "2024",
          ],
          axisBorder: {
            show: !1,
          },
          axisTicks: {
            show: !1,
          },
        },
        legend: {
          show: !1,
        },
      },
      series: [
        {
          name: "Donations",
          data: [
            0, 1500, 600, 1800, 900, 750, 3100, 5500, 4210, 2200, 3300, 1900,
            6500, 7500,
          ],
        },
        {
          name: "Disbursements",
          data: [
            0, 450, 1500, 360, 600, 2400, 3000, 3400, 6500, 2210, 5200, 5300,
            5900, 3500, 4500,
          ],
        },
        {
          name: "Extra",
          data: [
            0, 150, 1950, 210, 3600, 1200, 300, 1100, 2500, 4210, 1200, 2300,
            5900, 1500, 4500,
          ],
        },
      ],
    }
  }
  render() {
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <h4 className="card-title mb-4">Donations</h4>

            <Row className="text-center mt-4">
              <Col xs="3">
                <h5 className="font-size-20">$ 89425</h5>
                <p className="text-muted">Total Donations</p>
              </Col>
              <Col xs="3">
                <h5 className="font-size-20">$ 56210</h5>
                <p className="text-muted">Total Disbursements</p>
              </Col>
              <Col xs="3">
                <h5 className="font-size-20">$ 8974</h5>
                <p className="text-muted">Last Month Donation</p>
              </Col>
              <Col xs="3">
                <h5 className="font-size-20">$ 7974</h5>
                <p className="text-muted">Last Month Disbursements</p>
              </Col>
            </Row>

            <div
              id="morris-area-example"
              className="morris-charts morris-charts-height"
              dir="ltr"
            >
              <ReactApexChart
                options={this.state.options}
                series={this.state.series}
                type="area"
                height="300"
              />
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    )
  }
}

export default MonthlyEarnings
