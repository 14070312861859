import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import StaffForm from "./staff-form";
import StaffTable from "./staff-table";
import { DateFormater } from "utilities/dateformater";

export default function Staff() {
  const [create, setCreate] = useState(false);
  const [staffData, setStaffData] = useState({});
  const [updateId, setUpdateId] = useState();

  const forUpdate = staff_data => {
    console.log("for update staff", staff_data)
    let pickForUpdate = {
      id: staff_data?.id,
      firstName: staff_data?.firstName ? staff_data?.firstName : "",
      middleName: staff_data?.middleName ? staff_data?.middleName : "",
      lastName: staff_data?.lastName ? staff_data?.lastName : "",
      role: staff_data?.roles.length > 0 ? staff_data?.roles[0] : "",
      email: staff_data?.email ? staff_data?.email : "",
      userName: staff_data?.userName ? staff_data?.userName : staff_data?.email,
      phoneNumber: staff_data?.phoneNumber ? staff_data?.phoneNumber : "",
      dateOfBirth: staff_data?.dateOfBirth ? DateFormater(staff_data?.dateOfBirth) : "",
      password: null,
      confirmPassword: null,
    }
    setUpdateId(staff_data.id)
    setStaffData(pickForUpdate)
    setCreate(true)
  }

  const toggleTab = () => {
    setUpdateId();
    setStaffData({});
    setCreate(!create);
  };

  return (
    <div className="">
      <Row className="mb-4">
        <Col className="justify-content-end d-flex">
          <button
            className="switch-btn"
            onClick={toggleTab}
          >
            {create ? "View" : "Create"}
          </button>
        </Col>
      </Row>
      {create ? (
        <StaffForm staffData={staffData} updateId={updateId} toggleTab={toggleTab} />
      ) : (
        <StaffTable forUpdate={forUpdate} />
      )}
    </div>
  );
}
