import {
  InputField,
  InputImageField,
  TextareaField,
} from "components/Common/input-fields"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { allApplications } from "redux/slice/applications/applicationSlice"

export default function AuthorizationInfoForm({
  tabs,
  updateTabComplete,
  handleChangeInfo,
  handleFileChange,
  handleFileRemove,
  authorizationInfo,
  forView,
}) {
  let infoObject = "authorization"
  const { applicationsQuestions } = useSelector(allApplications)

  return (
    <React.Fragment>
      <h5 className="mt-4 text-secondary-si section-heading page-break">Authorization Details</h5>
      {
        applicationsQuestions ? applicationsQuestions.map((ques) => {
          const answerObject = authorizationInfo.answers.find(ans => ans.questionId === ques.id);
          // console.log("answer object", answerObject, authorizationInfo.answers)
          // console.log("answer object", ques.id, answerObject)
          return (
            <div key={ques.id}>
              <TextareaField
                label={ques?.question}
                className="col-sm-12 col-md-12 col-lg-12 my-2"
                compulsory={true}
                disabled={forView}
                rows="3"
                value={answerObject ? answerObject?.answer : ''}
                placeholder="Enter Application Consideration"
                onChange={e =>
                  handleChangeInfo(
                    infoObject,
                    `answer_${ques.id}`,
                    e.target.value,
                  )
                }
              />
            </div>
          )
        }) : null
      }
      {/* <InputField
        label="Place"
        compulsory={true}
        disabled={forView}
        type="text"
        value={authorizationInfo.place}
        placeholder="Enter Place"
        onChange={e => handleChangeInfo(infoObject, "place", e.target.value)}
      /> */}
      <InputField
        label="Submited Date"
        compulsory={true}
        disabled={forView}
        type="date"
        value={authorizationInfo.submittedDate}
        placeholder="Select Date"
        onChange={e => handleChangeInfo(infoObject, "submittedDate", e.target.value)}
      />
      <InputField
        label="IP Address"
        compulsory={true}
        disabled={forView}
        type="text"
        value={authorizationInfo.ipAddress}
        placeholder="Enter IP Address"
        onChange={e =>
          handleChangeInfo(infoObject, "ipAddress", e.target.value)
        }
      />
      <InputImageField
        label="Signature"
        compulsory={true}
        disabled={forView}
        type="file"
        value={authorizationInfo.signature}
        placeholder="Upload Signature"
        handleFileRemove={() => handleFileRemove("signature")}
        onChange={e => handleFileChange(infoObject, "signature", e)}
      />
    </React.Fragment>
  )
}
